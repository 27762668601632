import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { SitePage } from 'components/layout';
import { Container, Grid } from '@material-ui/core';
import not_found from '../assets/not-found.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '80%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  }),
);

export default function notFound() {
  const classes = useStyles();
  return (
    <SitePage>
      <Container className={classes.root}>
        <Grid item xs={12} sm={8} container justify="center">
          <img src={not_found} className={classes.image} />
        </Grid>
      </Container>
    </SitePage>
  );
}
